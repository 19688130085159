@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');

.newsec{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

}
.newsheader{
  font-family: 'Inter', sans-serif;
  font-size: 15px;
}
.ncard {
  width: 98%;
  height: 100%;
  border-radius: 6px;
  display: flex;
  padding: 10px;
  }
 .latestnews{
  width: 49%;
  height: 100%;

 }
 .news{
  width: 50%;
  height: 100%;
  overflow: auto;
  display: flex;
  background-color: white;
  flex-direction: column;
 }
 .imgnews{
   width: 95%;
   height: 40%;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 10px;
 }
 .imgnews img{
  width: 100%;
  height: 90%;
 }
 .newscon{
    width: 95%;
    height: 200px;
    padding: 10px;
    margin: 5px;
 }