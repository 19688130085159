.myButton {
	box-shadow:inset 0px 1px 0px 0px #54a3f7;
	background:linear-gradient(to bottom, #007dc1 5%, #0061a7 100%);
	background-color:#007dc1;
	border-radius:3px;
	border:1px solid #124d77;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:13px;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #154682;
}

.myButton:hover {
	background:linear-gradient(to bottom, #0061a7 5%, #007dc1 100%);
	background-color:#0061a7;
}
.myButton:active {
	position:relative;
	top:1px;
}


.myButton1 {
	box-shadow:inset 0px 1px 0px 0px #020300;
	background:white;
	background-color:#031735;
	border-radius:3px;
	border:2px solid #04010c;
	display:inline-block;
	cursor:pointer;
	color:#0f0e0e;
	font-family:Arial;
	font-size:13px;
	font-weight:bold;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #528009;
}
.myButton1:hover {
	background:linear-gradient(to bottom, #77a809 5%, #89c403 100%);
	background-color:#77a809;
}
.myButton1:active {
	position:relative;
	top:1px;
}


.myButton2 {
	box-shadow:inset 0px 1px 0px 0px #f5978e;
	background:linear-gradient(to bottom, #f24537 5%, #c62d1f 100%);
	background-color:#f24537;
	border-radius:3px;
	border:1px solid #d02718;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:13px;
	font-weight:bold;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #810e05;
}
.myButton2:hover {
	background:linear-gradient(to bottom, #c62d1f 5%, #f24537 100%);
	background-color:#c62d1f;
}
.myButton2:active {
	position:relative;
	top:1px;
}
.btnofficials{
	background-color:#2e8b57;
	border-radius:5px;
	border:2px solid #058d40;
	display:inline-block;
	cursor:pointer;
	color:#dcdcdc;
	font-family:Arial;
	font-size:13px;
	font-weight:bold;
	padding:6px 17px;
	text-decoration:none;
	margin-right: 5px;
}
.btnofficials:hover {
	background-color:#1c5736;
	color: white;
	border-radius:5px;
}
.btnofficials1{
	background-color:#043F97;
	border-radius:5px;
	border:2px solid #000307;
	display:inline-block;
	cursor:pointer;
	color:#dcdcdc;
	font-family:Arial;
	font-size:13px;
	font-weight:bold;
	padding:6px 17px;
	text-decoration:none;
}
.btnofficials1:hover {
	background-color:#012355;
	color: white;
	border-radius:5px;
}
.btnofficials2{
	background-color:#af002a;
	border-radius:5px;
	border:2px solid #960325;
	display:inline-block;
	cursor:pointer;
	color:#dcdcdc;
	font-family:Arial;
	font-size:13px;
	font-weight:bold;
	padding:6px 17px;
	text-decoration:none;
}
.btnofficials2:hover {
	background-color:#75021d;
	color: white;
	border-radius:5px;
}
.btnofficials3{
	background-color:white;
	border-radius:5px;
	border:2px solid #020000;
	display:inline-block;
	cursor:pointer;
	color:#0f0000;
	font-family:Arial;
	font-size:13px;
	font-weight:bold;
	padding:6px 17px;
	text-decoration:none;
}
.btnofficials3:hover {
	background-color:#050001;
	color: white;
	border-radius:5px;
}