.batchfilterCont{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 15px 0px 0px 0px;
}
.filterli{
    width: 300px;
}
.filterinput{
    height: 70px;
}
.searchbtn{
    width: 300px;
    height: 55px;
}
.batchfilter{
  margin-top: 10px;
  padding: 10px;
  box-shadow: -2px -18px 35px -2px rgba(175,165,165,0.75) inset;
-webkit-box-shadow: -2px -18px 35px -2px rgba(175,165,165,0.75) inset;
-moz-box-shadow: -2px -18px 35px -2px rgba(175,165,165,0.75) inset;
}





