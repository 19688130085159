.home{
    display: flex;
    height: 100vh;

    .homeContainer{
        flex: 7;
        background-color: rgb(249, 249, 249);
        height: max-content;

        .widgets, .charts{
            display: flex;
            padding: 10px;
            gap: 10px;
        }

        .charts{
            padding: 5px 20px;
        }

        .listContainer{
        padding: 10px;
        margin: 10px;
        border-radius: 5px;

        h1{
            font-size: 35px;
            font-weight: 600;
            margin: 5px;
        }

        .dataGrid{
            font-size: 10px;
            color: #333;
            background-color: #fff;
            border-radius: 10px;
            
        }

        }
        
        .listTitle{
            font-weight: 500;
            color: #333;
            margin-bottom: 15px;
        }
    }
}