.login{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-image: linear-gradient(180deg, rgb(237, 43, 0), rgb(3, 1, 155));
    height: 100vh;
    min-width: max-content;

    .container{
        width: max-content;
        height: 480px;
        display: flex;
        
        border-radius: 5px;
        background-color: rgb(255, 255, 255);
        .toplogin{
            width: 400px;
            height: 480px;
            display: flex;
            justify-content: center;
            align-items: center;

            h1{
                color: rgba(32, 76, 234, 1);
            }
            .mydo{

                width: 100%;
                height: 50%;
                object-fit: cover;
            }
        }

        form{
            width: 400px;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            align-items: center;
           
            .headlogin{
                text-align: left;
                width: 86%;
                margin-bottom: 20px;
                color: #000000;

                h1{
                    color: rgb(0, 0, 0);
                    font-size: 35px;
                    font-weight: 500;
                }

                i{
                    color: #606060;
                }
            }

            .form-group{
                margin-bottom: 10px;
                margin-left: 10px;

                .customcheck{
                    font-size: 5px;

                    .input{
                        display: flex;
                        font-size: 10px;
                        font-style: italic;
                    }
                }
            }

            .btnlogin{
                display: flex;
                position: relative;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 200px;
                height: 30px;
                
            }

            .myButtond{
                border: none;
                background-color: #FFB800;
                border-radius: 5px;
                width: 160px;
                padding: 10px;
                font-weight: 900;
                font-size: 20;
                font-family: sans-serif;
                cursor: pointer;
                box-shadow: 0px 1px 1px 0px rgb(126, 123, 123);
            }

            .myButtond:hover{
                background-color: #f0ad03;
            }
        }
    }
}

@media (max-width: 700px){

}