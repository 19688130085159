.single{
    display: flex;

    .singleContainer{
        flex: 7;

        .top{
            padding: 20px;
            display: flex;
            gap: 20px;
        }

        .left{
            flex: 1;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            position: relative;

            .editButton{
                position: absolute;
                top: 0;
                right: 0;  
                padding: 5px;
                font-size: 12px;
                color: #005427;
                background-color: lightgreen;
                cursor: pointer;
                border-radius: 0px, 0px, 0px, 5px;
            }

    }
    }

}