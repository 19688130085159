.questionchoose{
    width: 100%;
    display: flex;
    margin: 25px 20px 10px 10px;
    flex-direction: column;
    padding: 10px;
    background-color: white;
    border: 2px solid rgb(47, 150, 219);
    height: max-content;
}
.questionchoose .questioncon p{
    font-size: 19px;
    width: 70%;
    font-weight: 700;
    height: max-content;
    margin: 0px;
}
.questioncon{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.choiceli{
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    font-size: 17px;
    display: flex;
    margin: 5px;
    justify-content: space-between;
    align-items: top;
    height: max-content;
}
.choiceli p{
    width: 88%;
    margin: 0px;
    height: max-content;
}
.addchoice{
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}
.scoreinput{
    width: 60px;
}
.questcon{
    width: 90%;
    height: max-content;
    display: flex;
    margin: 5px;
    justify-content: space-between;
    align-items: center; 
}
.frmcontainer{
    margin: 0;
    padding: 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(600px, 1fr));
    gap: 0px;
    height: max-content;
    margin-bottom: 10px;
}
.questionscore{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.pscoreinst{
    margin: 0px;
}
.pscorehead{
    font-weight: bold;
    margin: 0px;
}
.swal2-container {
    z-index: 9999; /* Set a high z-index value */
  }
  .scorecardh{
    font-family: Roboto Serif;
    font-size: 32px;
    font-weight: 900;
    line-height: 37px;
    letter-spacing: 0.05em;
    text-align: left;
    color:#043F97 ; 
  }
  .btnclear{
    border: none;
    color: red;
    background-color: transparent;
  }