.users{
    display: flex;
    background-color: #f1f3fa;   
    height: 100vh;
    overflow: auto;
    .usersContainer{
        flex: 7;
        background-color: #f1f3fa;  
        .userlistcon{
        margin: 10px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        }

        h1{
            margin: 5px;
            color: #666;
            font-weight: 1000;
        }

        .dataGrid{
           width: max-content;
            font-size: 12px;
            font-weight: 600;
        }

        .deactivateBtn{
            border: none;
            background-color: rgb(255, 235, 235);
            color: rgb(255, 140, 0);
            padding: 5px;
            margin: 2px;
            border-radius: 5px;
            font-size: 11px;
            font-weight: 1000;
            cursor: pointer;
        }
       
    }
}

