
.contact{
    display: flex;
    background-color: #f1f3fa;  
    height: 100vh;
    overflow: auto;
    .contactContainer{
        flex: 7;
        background-color: #f1f3fa; 
        .top{
            margin: 10px;
            padding: 15px;
            border-radius: 10px;

            h1{
                color: #666;
                font-weight: 1000;
                margin-bottom: 5px;
                padding: 3px;
            }

            .cards{
                display: flex;
                text-align: center;
                gap: 15px;
                height: 60vh;
                justify-content: center;
                
                
            .contact{
                flex-basis: 38%;
                 margin: 10px;
                 padding: 10px;
                 width: 100%;
                 border-radius: 10px;
                 text-align: center;
                 justify-content: center;

                 h2{
                    justify-content: center;
                    margin: 5px;
                   
                }

               
            }
            }
            
            .dataTbl{
                font-size: 12px;
                font-weight: 600;
            }
        }
    }
}

.form{
    padding: 10px;
    margin: 40px;
    border: none;
    border-radius: 10px;

    .modalBtn{
        margin: 10px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;

        .cnclBttn{
            height: 35px;
            width: 80px;
            margin: 10px;
            border: none;
            border-radius: 10px;
            background-color: #ace7b6;
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
        }

        .addBttn{
            height: 35px;
            width: 80px;
            margin: 10px;
            border: none;
            border-radius: 10px;
            background-color: #ace7b6;
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
        }
    }
}

.addBtn{
    float: right;
    margin-top: -40px;
    margin-right: 10px;
    width: 80px;
    height: 30px;
    border: none;
    border-radius: 10px;
    background-color: #ace7b6;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}
p{
    margin: 0px;
}







