.about{
    display: flex;
    background-color: #f1f3fa;
    height: 100vh;
    overflow: auto;
    .aboutContainer{
        flex: 7;

        background-color: #f1f3fa;
        .top{
        margin: 20px;
        padding: 20px;
        }

        h1{
            font-size: 40px;
            font-weight: 1000;
            color: #666;

        }
    }
}

.sheets{
    width: 100%;
    height: 100vh;
    

}

