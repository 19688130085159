.rulecon{
    margin: 30px;
    padding: 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(400px, 1fr));
    grid-gap: 10px;
    height: 100%;
    margin-top: 0px;
}
.rulecon1{
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.rulecon1 .logos{
    margin: 0px 10px 0px 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.buttonrules{
    width: 95%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
