.navbar1{
   width:100%;
   height: 80px;
   border-bottom: 2px solid #fff;
   display: flex;
   font-size: 14px;
   margin: 0;
   padding: 0;
   top: 0;
   z-index: 999;

   .wrapper1{
    width: 100%;
    height: 100%;
    padding: 22px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: rgb(47, 150, 219);
    margin: 0;

    .search{
        display: flex;
        align-items: center;
        border: 0.5px solid rgb(47, 150, 219);
        padding: 5px;
        border-radius: 10px;
        justify-content: space-between;
        background-color: white;
        width: 20%;

        input{
            border: none;
            outline: none;
            background-color: #ffffff;

            &::placeholder{
                font-size: 12px;
            }
        }
    }
    
    .items{
        display: flex;
        align-items: center;

        .item{
             display: flex;
             align-items: center;
             position: relative;
             color: white;
             .icon{
                font-size: 30px;
                color: white;
             }

            .avatar{
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }

            .counter{
                width: 15px;
                height: 15px;
                background-color: red;
                border-radius: 50%;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 9px;
                font-weight: bold;
                position: absolute;
                top: -5px;
                right: -5px;
            }
        }
    }
   }
}

/* NotificationList.css */

.notification-list {
    list-style-type: none;
    padding: 0;
  }
  
  .notification-item {
    background-color: #f0f0f0;
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
  }
  
  .notification-item:hover {
    background-color: #e0e0e0;
  }
  .timestamp{
    font-family:'Roboto', sans-serif !important ;
    font-size: 12px;

  }
  .unreadtime{
    color: #043F97;
    font-weight: 900;
    font-family:'Roboto', sans-serif ;
    font-size: 12px;
  }