.applicant{
    display: flex;
    width: 100%;
    background-color: #f1f3fa;  
    height: 100vh;
    overflow: auto;
    .applicantContainer{
        flex: 7;
        background-color: #f1f3fa;  
        .top{
            width: 100%;
            border: none;

        h1{
            padding: 10px;
            color: #666;
        }
    }
        .cellAction{
            background-color: lightgreen;
            padding: 5px;
            border-radius: 5px;
            text-align: center;
        
            .viewButton{
                color: #005427;
                cursor: pointer;
            }

            .status{
                padding: 5px;

                &.Qualified{
                    color: aqua;
                    background-color: #798d82;
                }
            }
        } 
}

}

.PA{
   height: 100%;
   display: flex;
   justify-content: center;
   flex-direction: column;
   margin-bottom: 30px;
 

    .Pinfo{

       p{
        padding: 5px;
        font-size: 15px;
        font-weight: 600;
        font-style: italic;
        gap: 20px;
        margin-left: 10px;
       }
    }

    .Finfo{
        
       
       p{
        padding: 5px;
        font-size: 15px;
        font-weight: 600;
        font-style: italic;
        gap: 20px;
        margin-left: 10px;
       }
       
    }

    .Einfo{
        
       
       p{
        padding: 5px;
        font-size: 15px;
        font-weight: 600;
        font-style: italic;
        gap: 20px;
        margin-left: 10px;
       }
    }

    .Eduinfo{
        
       p{
        padding: 5px;
        font-size: 15px;
        font-weight: 600;
        font-style: italic;
        gap: 20px;
        margin-left: 10px;
       }

    }

    .Docuinfo{
       
       p{
        padding: 5px;
        font-size: 15px;
        font-weight: 600;
        font-style: italic;
        gap: 20px;
        margin-left: 10px;
       }

    }
}

.modalContainer{
    width: 100%;
    display: flex;

    .buttonclosed{

        button{
        margin: 0;
        padding: 5px;
        border-radius: 6px;
        background-color: #005427;
        color: #fff;
        font-weight: 600;
        border: none;
        float: right;
        display: flex;
        width: 35px;
        justify-content: center;
        }
    }
    
    
    .header{
        font-weight: 600;
        font-size: 15px;
        text-align: center;
        
        justify-content: center;
        align-items: center;
        padding: 10px;

        label{
            font-size: 30px;
            text-align: center;

        }

        span{
            font-size: 20px;
            text-align: center;

        }
    }
   
}

.buttonbacapp{
    position: fixed;
    bottom: 0px;
    display: flex;
    text-align: center;
    justify-content: center;
    gap: 20px;
    margin: 20px;

    button{
        flex-basis: 16%;
        height: 30px;
        width: 80px;
        border-radius: 10px;
        border: none;
        color: #fff;
        background-color: #ffd000;
        font-weight: 600;
        box-shadow: 0.5px 2px 1px 1px rgb(173, 157, 115);
    }
}





