.subdocsappdet{
    margin: 0;
    width: 98.9%;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(200px, 1fr));
    grid-gap: 10px;
    background-color: white;
    min-height: 100vh;
    max-height: max-content;
    border: 2px solid #252525; 
    padding: 14.2px 0px 0px 10px;
    overflow: auto;
}
.appuserfrm{
    width: 98.9%;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(400px,1fr));
    background-color: white;
    margin-right: 10px;
    padding: 10px;
    padding-top: 10px;
    background-color: white;
    min-height: 100vh;
    max-height: max-content;
    border: 2px solid #252525;
}
.imgdocuapt{
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
}
.imgdocuapt img{
    width: 300px;
    height: 300px;
    object-fit: contain;
}
.aptfrmuserscore{
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    padding: 10px;
    padding-top: 10px;
    background-color: white;
    min-height: 100vh;
    max-height: max-content;
    max-height: max-content;
    border: 2px solid #252525; 
    overflow: auto;
}
.aptuser{
    margin-bottom: 10px;
}
.aptuser p{
    font-size: 15px;
    margin-bottom: 10px;
}
.aptuser p strong{
    color: #252525;
}
.aptheader{
    font-size: 20px;
    margin: 10px 0px 5px 0px;
}
.aptuserdetails{
    width:100%;
    height: max-content;
    padding: 10px 0px 10px 0px;
    flex-direction: column;
    background-color: #252525;
    border-bottom: 3.5px solid #252525;
    color: white;
}
.aptuserdetails p{
    margin: 15px;
    font-size: 15px;
}

.imgprofatp{
    width: 100%;
    margin-top: 10px;
    height: max-content;
    min-height: 500px;
    border: 2px solid #252525;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 20px;
}
.imgprofatp img{
    width: 100%;
    max-height: 600px;
    min-height: 500px;
    object-fit: cover;
    border-top-left-radius: 20px;
}
.imgprofatp p{
    font-size: 30px;
    font-weight: bold;
}
.evalActivepage1{
    transition: all 0.3s ease-in-out;
    padding: 10px 30px 5px 20px;
    background-color: white;
    border: none;
    font-weight: 900;
    width: 250px;
    border-top: 5px solid #0EB3E8;
}
.evalPage1{
    transition: all 0.3s ease-in-out;
    padding: 10px 30px 5px 20px;
    background-color: white;
    border: none;
    width: 200px;
    font-weight: 700;
}
.appDocs{
    width: 100%;
    height: max-content;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.docslistapp{
    flex: 1;
    display: flex;
    position: relative;
    flex-direction: column;
    flex-basis: calc(50% - 20px);
    min-width: 200px;
    max-width: calc(50% - 20px);
    height: 250px;
    padding: 10px;
    margin: 5px;
}
.docslistapp img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.docslistapp p{
    top: -10px;
    font-weight: 700;
}