.news{
    display: flex;

    .newscontainer{
        flex: 7;


        .newsCon{
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            margin: 10px;
            padding: 10px;
            border-radius: 10px;

            .headnews{
                h1{
                    color: #005427;
                    font-weight: 1000;
                    padding: 5px;
                }

                button{
                    padding: 5px;
                    float: right;
                    width: 100px;
                    height: 30px;
                    border: none;
                    border-radius: 10px;
                    background-color: #ace7b6;
                    font-size: 14px;
                    font-weight: 600;
                    color: #005427;
                    cursor: pointer;
                    margin-top: -40px;
                    margin-right: 10px;
                }

            }
        }
   
        }

    .content{
        margin: 5px;
        height: max-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: rgb(170, 231, 203);
        border-radius: 5px;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        
        .newses{
            display: flex;
            margin: 10px;
            width: 70%;
            height: 60%;
            margin: 8px;
            background-color: #fff;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(58, 46, 46, 0.47);
            border-radius: 10px;
            justify-content: space-evenly;
            gap: 30px;
        }

        .pictit{
            padding: 10px;
            position: relative;

        img{
            width: 200px;
            height: 100px;
            border-radius: 10px;
            position: relative;
        }
        }

        .desdat{
            margin: 10px;
            padding: 10px;
            font-size: 20px;
            justify-content: space-between;
            text-align: center;
            align-items: center;
            display: flex;
            gap: 30px;
            position: relative;

            .subdesdata{
                flex-direction: row;
                text-align: center;
                justify-content: space-evenly;
                align-items: center;
                color: #005427;
                font-size: 14px;

                span{
                    padding: 10px;
                    font-size: 20px;
                    font-weight: 600;

                }
            }
        }
    }
     

    .create_news{
        position: fixed;
        display: flex;
        flex-direction: column;
        top: 50%;
        left: 55%;
        transform: translate(-50%, -50%);
        background: #bed6ca;
        width: 40%;
        border-radius: 20px;
        z-index: 1;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(58, 46, 46, 0.47);

    }

    form{
        display: flex;
        height: 450px;
        align-items: center;
        flex-direction: column;
        

        .field{
            width: 400px;
            height: 25px;
            font-size: 14px;
            padding: 5px;
            border-radius: 10px;
            border: none;
            justify-content: center;
            align-items: center;
        }

        .textArea{
            padding: 5px;
            width: 400px;
            height: 25px;
            font-size: 15px;
            border-radius: 10px;
            justify-content: center;
            align-items: center;
        }
 
        button{
            width: 150px;
            height: 40px;
            border: none;
            background-color: #005427;
            color: #fff;
            border-radius: 10px;
            font-weight: 600;
        }

        .chooseimgnews{
            display: flex;
            gap: 20px;
            text-align: center;
            justify-content: center;
            align-items: center;
            margin: 5px;
    
            .newsimgprev{
                gap: 20px;
                justify-content: center;
                align-items: center;
                text-align: center;

                .label{
                    display: flex;
                    margin: 10px;
                    padding: 10px;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                }

              
            .file{
                color: #005427;
                font-size: 14px;
                margin: 20px;
                padding: 10px;
                display: flex;
                justify-content: center;
                }
     
            .previmg{
               margin: 0;
               padding: 0;
               border-radius: 10px;
            }
        }
    }
}

.xbtn button{
    float: right;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-weight: 1000;
    background-color: #005427;
    border: none;
    padding: 5px;
    margin: 12px;
    height: 30px;
    width: 30px;
}



}