.totalicon{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 190px;
    height: 43px;
    padding: 10px;
    margin: 0px 10px 0px 10px;
    border-radius: 10px;
    background: #00FFA3;
}
.recentapph{
    font-family: Roboto Serif;
    font-size: 32px;
    font-weight: 900;
    line-height: 37px;
    letter-spacing: 0.05em;
    text-align: left;
    color:#043F97 ;

}