.PA{
    width: 100%;
    height: 100%;
    padding: 10px;
}
.PA .Pinfo .fstcolm{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(400px, 1fr));
    grid-gap: 10px;
    height: 100%;
}
.PA .Pinfo .fstcolm div{
    margin: 5px;
}
.PA .Finfo{
    padding: 10px;
}
.fmbgconlisclmn{
    width: 100%;
    height: 100%;
    display: flex;
}
.fmbgconlisclmn div{
    margin: 10px;
}
.fmbgconlisclmn .withscore{
    display: flex;
    justify-content: center;
    align-items: center;
}
.evalusrprof{
    width: 40%;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    min-height: 75vh;
    max-height: max-content;
}
.evalusrprof img{
    width: 98%;
    min-height: 60%;
    margin-left: 20px;
    object-fit: cover;
    border: 2px solid #252525;
    border-top-left-radius: 20px;
}
.evalusridcontainer{
    width:97%;
    height: max-content;
    padding: 9.5px 0px 0px 0px;
    margin-left: 20px;
    display: flex;
    justify-content: top;
    align-items: center;
    flex-direction: column;
    background-color: #252525;
    border-bottom: 3.5px solid #252525;
}
.evalusrid{
    width: 80%;
    padding-bottom: 10px;
    font-size: 20px;
    color: white;
}
.evalfrmdet{
    width: 60%;
    height: 100%;
}
.sheetsfrmeval{
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    padding: 10px;
    padding-top: 20px;
    background-color: white;
    min-height: 75vh;
    border: 2px solid #252525;
}
.sheetsfrmeval h4{
    margin-bottom: 10px;
}
.frmlistq{
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 70px;
    margin: 0px 0px 15px 0px;
}
.evaluationcon{
    width: 100%;
}
.taboneeval{
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    padding: 10px;
    padding-top: 20px;
    background-color: white;
    min-height: 75vh;
    max-height: max-content;
    border: 2px solid #252525;
}
.containertabs{
    display: flex;
    justify-content: space-around;
    border-bottom: 2px solid grey;
}
.tbscon{
    width: 48%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
.tbscon p{
    font-size: 18px;
    margin-bottom: 25px;
}
.tbscon p strong{
    color: #252525;
}
.containertabs1{
    padding-top: 20px;
    display: flex;
    justify-content: space-around;
}
.tabtwoeval{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(400px, 1fr));
    gap: 10px;
    margin-right: 10px;
    padding: 10px;
    padding-top: 20px;
    background-color: white;
    min-height: 75vh;
    max-height: max-content;
    border: 2px solid #252525; 
}
.evalusrcontainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    background-color: white;
    min-height: 75vh;
    max-height: max-content;
}
.evalfam{
    height: auto !important ;
}
.evalfam h2{
    color: #664;
    border-bottom: 2px solid #252525;
    margin-bottom: 10px;
}
.evalfam p{
    margin-bottom: 15px;
}
.siblingcon h2{
    color: #664;
    border-bottom: 2px solid #252525;
    margin-bottom: 10px;
}
.evalActivepage{
    transition: all 0.3s ease-in-out;
    padding: 15px 40px 10px 30px;
    background-color: white;
    border: none;
    font-weight: 900;
    width: 300px;
    border-top: 5px solid #0EB3E8;
}
.evalPage{
    transition: all 0.3s ease-in-out;
    padding: 15px 40px 10px 30px;
    background-color: white;
    border: none;
    width: 250px;
    font-weight: 700;
}
.formpersona{
    width: 100%;
    height: max-content;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.formpersonaChild {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-basis: calc(33.33% - 20px);
    min-width: 200px;
    max-width: calc(50% - 20px);
    height: 100px;
    padding: 10px;
    margin: 5px;
}
.formpersonaChild1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
    padding: 10px;
    margin: 5px;
}

.formpersonaChild:nth-child(3n + 1) {
    flex-basis: calc(50% - 20px);
}
.formpersonaChild label,.formpersonaChild1 label,.fdetails div label{
    text-transform: uppercase;
    font-weight: bold;
}
.formpersonaChild input,.formpersonaChild1 input{
    padding: 10px;
    font-weight: bold;
}
.famcon{
    width: 100%;
    height: max-content;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.famcon div{
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-basis: calc(50.33% - 20px);
}
.fdetails{
    width: 100%;
    height: max-content;
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.fdetails div{
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-basis: calc(33.33% - 20px);
    min-width: 200px;
    max-width: calc(100% - 20px);
    height: 100%;
    margin: 5px;
}
.fdetails div input{
    padding: 10px;
    font-weight: bold;
    width: 100%; 
}



