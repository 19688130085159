:root {
    --gold: #ffb338;
    --light-shadow: #77571d;
    --dark-shadow: #3e2904;
  }

.scholog{
    width: 100%;
    height: 60%;
}

.reqlistcontainer{
    width: 100%;
}
.requirelist{
    width: 100%;
    display: flex;
    height: 100%;
  }
  .requireprev {
    width: 20%;
    min-height: 100%;
    margin: 10px;
  }
  .requireprev img{
    width: 100%;
    height: 100px;
    object-fit: cover;
  }
  .userlistreq{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
  
  }
  .userlistreq label{
    font-size: 20px;
    margin: 10px;
    align-items: flex-start;
    font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  }
  .schodivuser{
    width: 65%;
    height: 100%;
  }
  .schoinfuser{
    background-color: white;
    width: 98%;
    height: 100%;
    padding: 10px;
  }
.Schorenewfrm{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px,1fr));
  gap: 10px;
}
.renewdetcont{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 0px 10px;
}
.renewscho{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px,1fr));
  gap: 10px;
  width: 100%;
  height: max-content;
  margin-bottom: 20px;
}
.renewscho1{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
  gap: 10px;
  width: 100%;
  height: max-content;
}
.my-swal-popup {
  z-index: 99999 !important;
}
/* Styles for ImageGallery component */
.image-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.gallery-image {
  cursor: pointer;
  width: 150px; /* Set the width for gallery images */
  height: 150px; /* Set the height for gallery images */
}

/* Styles for FullscreenImagePopup component */
.fullscreen-image-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.fullscreen-image {
  display: flex;
  flex-direction: column;
}

.fullscreen-image-content {
  max-width: 1000px;
  max-height: 550px;
  object-fit: contain;
  display: flex;
  flex-direction: column;
}
.fullscreen-image img{
  width: 1000px;
  height: 550px;
  object-fit: contain;
}
.formpersonaChild2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-basis: calc(33.33% - 20px);
  min-width: 200px;
  max-width: calc(50% - 20px);
  height: 50px;
  padding: 5px;
  margin: 5px;
}
.formpersonaChild2 input{
  background-color: white;
}
.formpersonaChild3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-basis: calc(33.33% - 20px);
  min-width: 200px;
  max-width: calc(50% - 20px);
  height: 150px;
  padding: 5px;
  margin: 5px;
  position: relative;
}
.formpersona3{
  width: 100%;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.formpersona4{
  width: 45%;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border: 5px solid whitesmoke;
}

.formpersonaChild4 {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-basis: calc(33.33% - 20px);
  min-width: 200px;
  max-width: calc(50% - 20px);
  height: 200px;
  padding: 5px;
  margin: 5px;
  position: relative;
  border-radius: 5px;
  background-color: whitesmoke;
}
.formpersonaChild5 {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-basis: calc(43.33% - 20px);
  min-width: 200px;
  max-width: calc(50% - 20px);
  height: max-content;
  padding: 5px;
  margin: 5px;
  position: relative;
  border-radius: 5px;
}
.formpersonaChild4 label,.formpersonaChild5 label{
  text-transform: uppercase;
  font-weight: bold;
}
.formpersonaChild4 input,.formpersonaChild5 input{
  padding: 10px;
  font-weight: bold;
  height: max-content;
}