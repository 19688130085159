.scholarships{
    display: flex;
    background-color: #f1f3fa;  
    .scholarshipsContainer{
        flex: 7;
        width: 100%;
        background-color: #f1f3fa;  
        .top{
            margin: 10px;
            padding: 10px;

            h1{
                color: #005247;
                margin-left: 10px;
                margin: 10px;
                font-weight: 1000;
            }

            .addbtn{
                display: flex;
                float: right;
                height: 30px;
                width: 80px;
                margin-top: 10px;
                justify-content: center;
                text-align: center;
                align-items: center;
                border: none;
                font-size: 14px;
                font-weight: 600;
                border-radius: 10px;
                background-color: #ace7b6;
                color: #005427;
                cursor: pointer;
        }


            .addnew{
                float: right;
                position: relative;
                margin-top: -40px;
                margin-right: 10px;
                padding: 10px;
                border-radius: 10px;
                border: 2px solid #005247;
                cursor: pointer;
                color: #005247;
                background-color: rgb(228, 255, 235);
                font-weight: 600;
                font-size: 13px;
                
            }

            .cards{
                display: flex;

                .icon{
                    width: 20px;
                    height: 20px;
                    margin: 0;
                    cursor: pointer;
                    float: right;
                }

                .left, .right{
                    flex-basis: 50%;
                    height: 200px;
                    width: 200px;
                    padding: 20px;
                    margin: 10px;
                    border-radius: 10px;
                    background-color: #eafdf3;
                    justify-content: center;
                    align-items: center;

                    
                    h2{
                        text-align: center;
                        color: #005247;
                    }

                    p{
                        text-align: justify;
                        justify-content: center;
                        padding: 10px;
                    }

                    .applybtn{
                    margin: 20px;
                    margin-left: 220px;
                    padding: 10px;
                    border-radius: 10px;
                    border: 2px solid #005247;
                    cursor: pointer;
                    color: #005247;
                    background-color: rgb(228, 255, 235);
                    font-weight: 600;
                    font-size: 12px;
                    cursor: pointer;
                    }
                }

            }
          
        }

        .tableCell2{
            border: 1px solid #005427;
            color: #005247;
            text-align: center;
            font-size: 15px;
            font-weight: 600;
        }
    }

    .table{
        font-weight: 600;

        color: #005427;

        .tableCell{
            color: #005427;
            font-weight: 600;
            font-size: 12px;
            
    }
    }
    
}

.modalAddbtn{
    border: none;
    cursor: pointer;

    .box{
        border: none;
    }
}

.buttonclosed{
    float: right;
    padding: 10px;
    cursor: pointer;
    
    button{
        border: none;
        border-radius: 5px;
        background-color: #005247;
        color: #fff;
        font-weight: 600;
        height: 20px;
        width: 20px;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 2px;
        cursor: pointer;
    }
}

  .contentscho{
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 40px;
    gap: 20px;
    
    .formleft{
        text-align: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 10px;
        padding: 10px;
        font-weight: 600;
        gap: 20px;

        .schodetails{
            font-weight: 1000;
           
        }

        input{
            height: 35px;
            width: 250px;
            justify-content: center;
            align-items: center;
            padding-left: 10px;
            gap: 20px;
            margin: 10px;
        }
    }

    .buttonbacapp1{
        padding: 10px;
        margin: 10px;

        button{
            width: 150px;
            height: 40px;
            margin: 10px;
            border: none;
            border-radius: 10px;
            font-weight: 700;
            cursor: pointer;
        }
    }

    .status{
        gap: 20px;
        justify-content: center;
        align-items: center;
        text-align: center;

        .stat{
         
            font-size: 14px;
            font-weight: 600;
        }
       
    }

}



.content-scho{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
    margin: 10px;
    padding: 50px;

    .imgprev{

        .previmg{
            height: 150px;
            border-radius: 5px;
            box-shadow: 1px 1px 1px 1px #333d3c;
        }
    }

    .schocredet{
        flex-direction: column;
        height: 30vh;
    
        font-weight: 600;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin: 10px;
        padding: 50px;
        gap: 50px;

        .status{
            text-align: center;
            justify-content: center;
            align-items: center;
        }

        .option{
            display: flex;
            margin-left: 10%;

            .status{
                margin: 20px;
                gap: 20px;
        
                font-weight: 600;
            }

            .edtstatus{
            
                font-weight: 600;
                gap: 20px;
            }

        }
        
    
        input{
            height: 20px;
            text-align: center;
            width: 180px;
            gap: 20px;
            margin: 10px;
            padding: 5px;
            border-radius: 5px;
            border: none;
            background-color: #81d1a6;
            color: #fff;
            font-weight: 500;
        }

        textarea{
            text-align: center;
            width: 300px;
            gap: 20px;
            margin: 10px;
            padding: 5px;
            border-radius: 5px;
            border: none;
            background-color: #81d1a6;
            color: #fff;
            font-weight: 500;
        }

        .reqcontainer{

            .deleteBtn{
                font-size: 14px;
                font-weight: 600;
                border: none;
                border-radius: 5px;
                padding: 10px;
                background-color: #005247;
                color: #fff;
                cursor: pointer;
            }
        }

        .addReqbtn{
            font-size: 14px;
            font-weight: 600;
            border: none;
            border-radius: 5px;
            padding: 10px;
            background-color: #005247;
            color: #fff;
            cursor: pointer;
        }
    
    }
    
    .buttonbacapp1{
        gap: 20px;
        padding: 20px;
        margin: 10px;
    
        button{
            gap: 20px;
            margin: 20px;
            cursor: pointer;
        }
    
    }
    
}

.buttonbacapp2{
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 50px;

    button{
        width: 150px;
        height: 40px;
        margin: 10px;
        border: none;
        border-radius: 10px;
        font-weight: 700;
        cursor: pointer;
    }
}


.row{
    border:  1px solid #005427;
    

    .tableCell{
        border: 1px solid #005427;
        text-align: center;
        justify-content: center;
        align-items: center;
        

        img{
            border-radius: 5px;
        }

        .editButton{
            background-color: #81d1a6;
            padding: 10px;
            border: none;
            border-radius: 5px;
            font-weight: 600;
            font-size: 14px;
            cursor: pointer;
        }

 
    }
    
}


