.hidden-row {
    display: none;
  }
  .Pinfo{
    width: 100%;
    display: flex;
  }
  .Pinfo .fstcolm{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .Finfo .fmbgconlisclmn,.PA .fmbgconlisclmn{
    width: 100%;
    height: 20%;
    display: flex;
    margin: 0;
  }
  .docusersub{
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .clas{
    margin: 0;
    padding: 10px;
    width: 95%;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(500px, 1fr));
    grid-gap: 10px;
    height: 100%;
  }
  .PA{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Docuinfo{
    width: 100%;
    height: 100%;
  }
  .buttonclosed{
    width: 100%;
    height: auto;
  }
  .sublist{
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    float: left;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .subdocsprev{
    position: relative;
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .actions{
    width: 60%;
    height: 100%;
  }
  .requirement-name {
    position: absolute;
    bottom: 5px;
    left: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px;
    font-size: 14px;
  }