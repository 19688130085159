.payrollContent{
    padding: 5px;
}
.payrollContainer1{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.payrollContainer2{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.payrollTable{
    height: max-content;
    border-radius: 10px;
}
#component-to-print, #component-to-print * {
    visibility: hidden;
  }
  #component-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
@media print {
    body * {
      visibility: hidden;
    }
    @page {
        margin-top: 0;
        size: A3 landscape;
      }
      /* Hide the date and URL */
      .date, .url, .website, .footer, .url-container {
        display: none !important;
      }
    #component-to-print, #component-to-print * {
      visibility: visible;
    }
    #component-to-print {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }
    footer{
      position: fixed;
      width: 100%;
      height: 50px;
      bottom: 0;
      background-color: #0075FF;
      color: white;
      z-index: 999999px;
      text-align: center;
      font-weight: bold;
      padding: 10px;
    }
    footer div{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    footer div .iconspr{
      width: 25px;
      height:25px;
      margin-right: 10px;
      margin-left: 5px;
    }
  }