.appointment{
    width: 100%;
    display: flex;
    background-color: #f1f3fa;  
    height: 100vh;
    overflow: auto;

    .appointmentContainer{
        width: 100%;
        .top{
            width: 100%;
            display: flex;
            flex-direction: column;

            h1{
                color: #666;
                font-weight: 1000;
                font-size: 30px;
                display: flex;
                padding: 5px;
            }

            h2{
                color: #005427;
                font-weight: 1000;
                font-size: 20px;
                display: flex;
                padding: 5px;
            }

           .headerAppoint{
                display: flex;
                padding: 10px;
                gap: 700px;
                

            .viewAppointbtn{
                border-radius: 5px;
                padding: 5px;
                float: right;
                width: 200px;
                border: none;
                font-size: 15px;
                font-weight: 600;
                color: #005427;
                background-color: #d3e7dc;
                cursor: pointer;
            }
           }

           .frmappoint{
                margin: 10px;
                padding: 10px;     
                display: flex;
                justify-content: space-around;
                width: 95%;
                height: 100%;

            .datagloc{
                width: 45%;
                height: 100%;
                
                
                .dateAppoint, .agenda, .location{
                    width: 90%;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 5px;
                    padding: 5px;

                    .DateCalendar{
                        display: flex;
                        width: 100%;
                        height: 100%;
                    }

                }  
            }

            .timestend{
                width: 45%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: top;
                .appinf{
                    width: 100%;
                    margin-bottom: 20px;
                    height: 100%;
                }

            }
        }
        }
    }
}

.applicalist{
    padding: 10px;
    margin: 10px;

    .dataList{
        display: flex;
        padding: 5px;
        background-color: #005427;
        
        .appHead{
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            gap: 5px;
            

            .searchBox{
                margin-left: 10px;
                color: #005427;
                font-weight: 600;
                font-size: 14px;
                
                input{
                    height: 22px;
                    border: 1px solid #005427;
                    
                }
            }
        }
    }
    .applicantList{
        justify-content: center;
        align-items: center;
        text-align: center;
        display: flex;
        gap: 50px;
        margin: 10px;
        padding: 10px;
        

        .appointBtn, .dselectBtn{
          gap: 50px;
        }
    }
   

}

table{
    border: 1px solid #005427;
    display: flex;
    position: relative;
    font-size: 14px;
    text-align: center;
    justify-content: center;
    align-items: center;

    .tableCell{
        border: 1px solid #005427;
        font-size: 12px;
        font-weight: 600;
        
    }

}

.cards{
    margin: 5px;

}

