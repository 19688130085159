.faqs{
    display: flex;
    background-color: #f1f3fa;  
    .faqsContainer{
        flex: 7;
        background-color: #f1f3fa;  
        .top{
            margin: 10px;
            padding: 10px;
            border-radius: 10px;
      

            h1{
                color: #333;
                font-weight: 1000;
                font-size: 30px;
                margin: 5px;
                padding: 5px;
            }

            .containeremaccs{
                height: 100%;
                width: 100%;

                .emacsslist{
                    display: flex;
                    flex-direction: column;
                    width:100%;
                    padding: 10px;
                    
                    .bmccEmp{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        text-align: center;


                        .addBtnEmp{
                            border: none;
                            padding: 8px;
                            margin: 10px;
                            width: 300px;
                            border-radius: 10px;
                            font-size: 14px;
                            font-weight: 600;
                            color: blue;
                            background-color:  #ace7b6;
                            cursor: pointer;
                        }

                    }
                    
                }

                .emmaccsact{
                    padding: 10px;
                    width: 100%; 
                    

                    .dataGridCon{
                        width: 100%;
                        padding: 10px;
                        margin: 10px;
                    }
                }
              
            }

            .emplycon{
                margin: 10px;
                width: 92%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                text-align: center;
                align-items: center;
                margin: 10px;
                gap: 20px;
                border-radius: 10px;
                padding: 10px;
                

                .profemp{
                    margin: 5px;
                    display: flex;
                    padding: 10px;

                    border-radius: 50px;
                    
                }

                .detemp{
                    display: flex;
                    flex-direction: column;
                    padding: 5px;
                    margin: 5px;
                    
                    p{
                        margin: 2px;
                        font-size: 14px;
                        font-weight: 600;
                        color: blue;
                    }

                    .editBtnEmp{
                        border: none;
                        border-radius: 5px;
                        padding: 5px;
                        font-size: 12px;
                        font-weight: 600;
                        color: yellow;
                        background-color:  blue;
                        cursor: pointer;
                    }
                }
                
            }

            

        }

        
    }
    
}


