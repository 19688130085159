.sidebar{
    background-color: rgb(47, 150, 219);
    flex: 1;
    height: 100%;
    .top{
        background-color: rgb(47, 150, 219);
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 150px;
            height: 100%;
            object-fit: cover;
            padding-bottom: 10px;
            
        }
    }

    hr{
        height: 0;

    }

    .center{
        width: 100%;
        height: max-content;
        background-color: rgb(47, 150, 219);
        padding-left: 10px;
        padding-top: 10px;
        margin: 0px 6px 0px 0px;
        text-decoration: none;
        padding-bottom: 60px;
        ul{
            list-style: none;
            margin: 0;
            padding: 0;

            .title{
                font-size: 14px;
                font-weight: bold;
                color: #0047a4;
                margin: 12px;
            }

            li{
                display: flex;
                align-items: center;
                padding: 5px;
                cursor: pointer;
                

                &:hover{
                    background-color: blue;
                    color: white;
                    border-top-left-radius: 12px;
                    border-bottom-left-radius: 12px;
                }

                .icon{
                    font-size: 25px;
                    color: #fff;
                    padding: 2px;
                }

                span{
                    font-size: 13px;
                    font-weight: 700;
                    color: #fff;
                    margin-left: 10px;
                } 
            }
        }
    }

    .bottom{
        display: flex;
        align-items: center;
        margin: 10px;
    }

    .logoutBtn{
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        border: 2px solid;
        background-color: #1a1f22;

    }

}

/* ... Other styles ... */

.has-submenu {
    position: relative;
  }
  
  .has-submenu .submenu {
    display: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
  
  .has-submenu.open .submenu {
    display: block;
    max-height: 200px; /* Adjust as needed */
  }

  