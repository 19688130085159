.backup{
    display: flex;
    background-color: #f1f3fa;  
    height: 100vh;
    overflow: auto;
}
.backupactions{
    display: flex;
    width: 100%;
    margin-top: 20px;
}
.headbackup{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.listback{
    font-weight: bold;
    font-size: 25px;
}