@import '~bootstrap/scss/bootstrap';

.scholars{
    display: flex;
    background-color: #f1f3fa;
    .scholarsContainer{
        flex: 7;
        width: 100%;
        background-color: #f1f3fa;  
        .top{
            padding: 20px;

            h1{
                color: #666;
                font-weight: 1000;
                font-size: 35px;
            }

            .dataGrid{
                font-size: 12px;
                font-weight: 600;
                color: #005427;
            }
         }
    }
}

.viewBtnScholars{
    border: none;
    border-radius: 5px;
    margin: 5px;
    padding: 10px;
    color: #005427;
    background-color: #ace7b6;
    font-size: 12px;
    font-weight: 600;
}

.hbtnschocon{
    float: right;
    margin: 5px;

    .closedBtn{
        text-align: center;
        border: none;
        padding: 8px;
        margin: 5px;
        border-radius: 10px;
        color: rgb(222, 46, 15);
        background-color: #fac5c5;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        font-size: 15px;
        font-weight: 1000;
    }
}

.schoinfocon{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: row;
   border-radius: 10px;
   
   margin: 25px;

   h1{
    color: #005427;
    font-weight: 1000;
    font-size: 24px;
   }

   .leftscho{
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(48, 200, 25, 0.47);
    border-radius: 10px;
    margin: 30px;

    .profilescho{
        justify-content: center;
        text-align: center;
        align-items: center;
        border-radius: 10px;
        display: flex;
        flex-direction: column;

        .schopic{
            margin: 5px;
           -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(48, 200, 25, 0.47);
            border-radius: 10px;
            padding: 10px;
            width: 80%;
        }

        .schopicdet{
            justify-content: center;
            align-items: center;
            text-align: center;
            margin: 10px;
            padding: 10px;
            display: block;

            p{
                color: #005427;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

   }
}

.scholog{
    color: #005427;
    font-style: italic;
    height: max-content;
    
    .listTab{
        display: flex;
        flex-direction: column;
        

        .tabs{
            text-align: center;
            justify-content: center;
            align-items: center;
            font-weight: 1000;
        }
    }
}

.rightscho{
    display: flex;
    flex-direction: column;
    overflow: auto;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(48, 200, 25, 0.47);
    border-radius: 10px;
    padding: 12px;

    .personalInfo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        .peschocon{
            color: #005427;
            padding: 10px;
           
        }
    }

    .peschocon{
        color: #005427;
        padding: 10px; 
    }

    h1{
        text-align: center;
        margin: 5px;
        padding: 5px;
    }

    .doculistscho{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(200px, 1fr));
        grid-gap: 30px;
        height: 50vh;
        overflow: hidden;
    }

}