.renewheader{
    display: flex;
    justify-content: space-between;
}
.frmrenew{
    width: 1200px;
    min-height: 500px;
    max-height: max-content;
    background-color: white;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px,1fr));
    gap: 10px;
}
.frmrenew .frm{
    overflow: auto;
    padding: 10px 10px 10px 0px;
}
.frmrenew .frm p{
    margin: 0;
    padding: 0;
    color: #252525;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 5px;
}
.frmrenew .frm input{
    padding: 5px;
    margin-right: 10px;
}
.frmrenew .frm h2{
    font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    color: #252525;
    margin-top: 5px;
}
.frmrenew .frm ul{
    list-style-type: disc;
}
.frmrenew .frm ul li{
    padding: 5px 5px 5px 0px;
    margin: 5px 5px 5px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #252525;
}
.requireform{
    margin-top: 25px;
    padding: 0px 15px 0px 5px;
}
.requireform div{
    margin-bottom: 5px;
}
body{
  margin: 0;
  width: 100vw;
}
p{
  margin: 0px;
}
.css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel {
    display: none;
    margin: 0px;
}
.css-levciy-MuiTablePagination-displayedRows{
    margin: 0px;
}
